import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Twitter from "./Twitter"
import Facebook from "./Facebook"
import favicon from "../../images/mattress-logo.svg"
import { getVariables } from "../../lib/utils"

const SEO = ({
    title = null,
    description = null,
    image = null,
    pathname = null,
    article = false,
    canonical = false
}) => (
    <StaticQuery
        query={graphql`
      query SEOQuery {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultImage: image
            twitterUsername
            facebookAppID
          }
        }
      }
    `}
        render={({
            site: {
                siteMetadata: {
                    defaultTitle,
                    titleTemplate,
                    defaultDescription,
                    siteUrl,
                    defaultImage,
                    twitterUsername,
                    facebookAppID,
                },
            },
        }) => {
            const seo = {
                title: title || defaultTitle,
                description: description || defaultDescription,
                image: `${image || defaultImage}`,
                url: `${siteUrl}${pathname || '/'}`,
            }

            const variables = getVariables()

            let linkCanonical = []
            if (canonical) {
                linkCanonical = [{
                    rel: 'canonical',
                    key: 'https://' + variables.site + canonical,
                    href: 'https://' + variables.site + canonical,
                }]
            }

            return (
                <>
                    <Helmet title={seo.title} titleTemplate={titleTemplate} link={linkCanonical}>
                        <html lang="de" />
                        <link rel="icon" href={favicon} />
                        <meta name="description" content={seo.description} />
                        <meta name="image" content={seo.image} />
                    </Helmet>
                    <Facebook
                        pageUrl={seo.url}
                        type={article ? 'article' : null}
                        title={seo.title}
                        description={seo.description}
                        image={seo.image}
                        appID={facebookAppID}
                    />
                    <Twitter
                        username={twitterUsername}
                        title={seo.title}
                        description={seo.description}
                        image={seo.image}
                    />
                </>
            )
        }}
    />
)

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    pathname: PropTypes.string,
    article: PropTypes.bool,
}

export default SEO