import React from 'react'

export const MAX_COMBINATIONS = 4

/*
* Let's store some variables to use it globaly
*/
export function getVariables() {
    //common variables
    const options = {
        'site': 'de.mattress.zone',
        'home': 'Home',
        'mattress': 'matratze',
        'mattresses': 'matratzen',
        'mattress_vs': '-matratze-vs-',
        'compare': 'vergleichen sie',
        'comparer': 'vergleichen',
        'comparison': 'vergleich',
        'review': 'rezension',
        'reviews': 'bewertungen',
        'contact': 'kontakt',
        'privacy': 'privatsphäre',
        'message_limit': 'Sie haben die maximal zulässigen Produkte zum Vergleich ausgewählt. Bitte entfernen Sie eine Matratze, um eine weitere hinzuzufügen (drei zulässig).',
        'last_updated_on': 'Zuletzt aktualisiert am',
        'add_another_mattress': 'Fügen Sie eine weitere Matratze hinzu',
        'popular_comparisons': 'Beliebte Vergleiche',
        'enter_mattress_name': 'Matratzennamen eingeben',
        'add_title': 'Wählen Sie diese Matratze zum Vergleichen aus',
        'list_mattress_text': 'Wählen Sie aus diesen häufig verglichenen Matratzen',
        'view_article': 'Artikel ansehen',
        'remove': 'Gegenstand entfernen',
        'view_all_reviews': 'Alle Bewertungen anzeigen',
        'recent_reviews': 'Aktuelle Bewertungen',
        'shop_now': 'Jetzt einkaufen',
        'read_more': 'Weiterlesen',
        'price_range': 'Preisklasse',
        'firmness': 'Festigkeit',
        'mattress_type': 'Matratzentyp',

        'about_us': 'Vergleichen sie',
        'cookie_message': 'Diese Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erhalten.',
        'find_out_more': 'Mehr erfahren.',
        'guides': 'Leseanleitung',
        'company': 'Unternehmen',
        'region': 'Region',
        'alt_promotion': 'Dies ist ein Link zur {brand}-Website',
    }
    return options
}

/**
 * Return jsx components from a promotion array processed and ready to be inserted into a fragment
 * @param  {Array} promotions array of promotions from DATOcms
 * @return {JSX}      JSX frament
 */
export function getPromotions(promotions, brand_name) {
    let affiliate_link = null
    let shortened_affiliate_link = null
    const variables = getVariables()

    let start_date = null
    //let end_date = null   //TODO
    let show_promotion = false
    let today_date = new Date()

    let mattres_prom = []
    if (promotions && promotions.length) {
        //iterate the promotions in case there's more than one
        for (let i = 0; i < promotions.length; i++) {
            if (promotions[i].startDate) {
                start_date = new Date(promotions[0].startDate)
            }
            if (promotions[i].endDate) {
                //end_date = new Date(promotions[i].endDate)
            }

            if (promotions[i].affiliateLinkPromotion.affiliateLink) {
                affiliate_link = promotions[i].affiliateLinkPromotion.affiliateLink
            }
            if (promotions[i].affiliateLinkPromotion.shortenedAffiliateLink) {
                shortened_affiliate_link = promotions[i].affiliateLinkPromotion.shortenedAffiliateLink
            }

            if (start_date !== null) {
                //date setup so check ranges
                if (start_date.getTime() < today_date.getTime()) {
                    show_promotion = true
                }
            } else {
                show_promotion = true
            }

            //Added a default text on Chris request
            let alt_title = variables.alt_promotion
            if (promotions[i].altTitle) {
                alt_title = promotions[i].altTitle
            }
            alt_title = alt_title.replace('{brand}', brand_name)

            if (show_promotion) {
                mattres_prom.push(<div key={`item-promotions-key-${i}`}>
                    <div className="text-lg text-center text-zone-2 mb-2" dangerouslySetInnerHTML={{ __html: promotions[i].description }} />
                    <a
                        className="btn-coupon font-grotesk text-white bg-zone-6 p-4 mb-4 block uppercase text-center font-semibold rounded-3xl duration-300 hover:bg-zone-14" href={shortened_affiliate_link ? shortened_affiliate_link : affiliate_link}
                        target="_blank"
                        rel="nofollow noopener sponsored"
                        title={alt_title}
                    >
                        {promotions[i].affiliateLinkCta}
                    </a>
                </div>)
            }
        }
    }
    return mattres_prom
}

/**
 * Return a link to a valid promotion ready to be inserted into an anchor
 * @param  {Array} promotions array of promotions from DATOcms
 * @return {String}      Promotion link
 */
export function getPromotionLink(mattress) {
    let affiliateLink = null
    //Iterating the mattress lets check for the brand promotion first
    if (mattress.brand.promotions && mattress.brand.promotions.length) {
        affiliateLink = iteratePromotions(mattress.brand.promotions)
    } else {
        //no brand promotion, try product promotion
        if (mattress.promotions.length > 0) {
            affiliateLink = iteratePromotions(mattress.promotions)
        }
    }
    return affiliateLink
}

export function iteratePromotions(promotions) {
    let result_link = null
    let affiliate_link = null
    let shortened_affiliate_link = null

    let start_date = null
    //let end_date = null
    let show_promotion = false
    let today_date = new Date()

    if (promotions && promotions.length) {
        //iterate the promotions in case there's more than one
        for (let i = 0; i < promotions.length; i++) {
            if (promotions[i].startDate) {
                start_date = new Date(promotions[0].startDate)
            }
            if (promotions[i].endDate) {
                //end_date = new Date(promotions[i].endDate)
            }

            if (promotions[i].affiliateLinkPromotion.affiliateLink) {
                affiliate_link = promotions[i].affiliateLinkPromotion.affiliateLink
            }
            if (promotions[i].affiliateLinkPromotion.shortenedAffiliateLink) {
                shortened_affiliate_link = promotions[i].affiliateLinkPromotion.shortenedAffiliateLink
            }

            if (start_date !== null) {
                //date setup so check ranges
                if (start_date.getTime() < today_date.getTime()) {
                    show_promotion = true
                }
            } else {
                show_promotion = true
            }

            if (show_promotion) {
                if (shortened_affiliate_link) {
                    result_link = shortened_affiliate_link
                } else {
                    if (affiliate_link) {
                        result_link = affiliate_link
                    }
                }
            }
        }
    }
    return result_link
}

export function getNewestDate(dates) {
    let result = null

    if (Array.isArray(dates) && dates.length) {
        const months = [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember'
        ]

        let localUpdate = new Date(Math.max.apply(null, dates.map(function (e) {
            return new Date(e)
        })))

        let newestUpdate = new Date(localUpdate.toLocaleString("en-US", { timeZone: "Europe/Berlin" }))

        let month_id = newestUpdate.getMonth()
        let month = months[month_id]
        let day = newestUpdate.getDate()
        let year = newestUpdate.getFullYear()

        result = { 'day': day, 'month': month, 'year': year }
    }

    return result
}

export function generatePath(items = [], locale = 'en', countryCode = null, translateMattress = 'mattress') {
    let path
    if (items.length > 1) {
        path = locale === 'en' || locale === 'de' ? items.join('-' + translateMattress + '-vs-') : items.join('-vs-' + translateMattress + '-')
        path = locale === 'en' || locale === 'de' ? path + '-' + translateMattress : translateMattress + '-' + path
    } else {
        path = locale === 'en' || locale === 'de' ? items + '-' + translateMattress : translateMattress + '-' + items
    }

    let fullPath = '/' + path
    if (countryCode) {
        fullPath = '/' + countryCode + '/' + path
    }
    return fullPath
}